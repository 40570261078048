import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/LangAttribute.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/layout/MainLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/[lang]/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"400\",\"700\",\"300\",\"500\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--roboto\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/[lang]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/brolachess.ttf\",\"variable\":\"--fancy\"}],\"variableName\":\"fancyCut\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/[lang]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/arabic-heading.ttf\",\"variable\":\"--arabic-heading\"}],\"variableName\":\"arabicHeading\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/logo.png");
