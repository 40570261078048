'use client'

import { useParams } from 'next/navigation'
import { useEffect } from 'react'

export default function LangAttribute() {
  const params = useParams()
  const lang = params.lang as string

  useEffect(() => {
    if (lang) {
      document.documentElement.lang = lang
    }
  }, [lang])

  return null
}